import moment from "moment";
import React, { useContext } from "react";
import { GlobalStateContext } from "../../../../engine/GlobalState";
import { getMessage } from "../utils";
import "./MessegeBotComponent.css";

const MessegeBotComponent = (props) => {
  const context = useContext(GlobalStateContext);
  let messege = getMessage(props.dataMessege);

  return (
    <div
      id={`accw_message_${props.number}`}
      className={`ACCW_MessegeBotComponent_messege_wrapper_user ${
        props.stack && "ACCW_MessegeBotComponent_messege_wrapper_user_stack"
      }  ${context.styleGradient.value && "ACCW_gradient"}`}
    >
      <div className="ACCW_MessegeBotComponent_messege_wrapper_user_gradient"></div>
      <div style={{ display: "flex" }}>
        <div className="ACCW_MessegeBotComponent_messege_wrapper_img_wrapper">
          <div className="ACCW_MessegeBotComponent_messege_wrapper-avatar">
            <img
              alt=""
              src={context.agent.value.image}
              className="ACCW_MessegeBotComponent_messege_avatar"
            />
          </div>
        </div>
        <div className="ACCW_MessegeBotComponent">
          {messege}
          <span className="ACCW_MessegeBotComponent_timestamp">
            {" "}
            {moment(props.dataMessege.timestamp).format("HH:mm")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default MessegeBotComponent;
